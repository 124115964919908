<template>
  <div class="row">
    <h1 class="m-b-md">{{ $t("guides.video_guides") }}</h1>
    <!-- <MessageCard 
            :alertType="'indicator-info'"
            :alertTitle="$t('claims.new.comments')"
            :alertText="$t('guides.alert_message')"
        /> -->

    <div class="row m-t-xxl">
      <div class="col-xl-6 m-b-lg">
        <h4>Tablet - Create Drive Off</h4>
        <video style="width: 100%; height: 100%" controls>
          <source
            src="https://portal.varsanpr.com/videos/drive_off.mp4#t=1"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="col-xl-6 m-b-lg">
        <h4>Tablet - Create No Means of Payment</h4>
        <video style="width: 100%; height: 100%" controls>
          <source
            src="https://portal.varsanpr.com/videos/nmop.mp4#t=1"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="col-xl-6 m-t-lg m-b-lg">
        <h4>Tablet - Awaiting Upload</h4>
        <video style="width: 100%; height: 100%" controls>
          <source
            src="https://portal.varsanpr.com/videos/awaiting_upload.mp4#t=1"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="col-xl-6 m-t-lg m-b-lg">
        <h4>Tablet - Upload Evidence</h4>
        <video style="width: 100%; height: 100%" controls>
          <source
            src="https://portal.varsanpr.com/videos/upload_evidence.mp4#t=1"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="col-xl-6 m-t-lg m-b-lg">
        <h4>Tablet - Cancellation Request</h4>
        <video style="width: 100%; height: 100%" controls>
          <source
            src="https://portal.varsanpr.com/videos/cancellation_request.mp4#t=1"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="col-xl-6 m-t-lg m-b-lg">
        <h4>Tablet - DVLA Checker</h4>
        <video style="width: 100%; height: 100%" controls>
          <source
            src="https://portal.varsanpr.com/videos/dvla_checker.mp4#t=1"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import MessageCard from "../../components/MessageCard.vue";

export default {
  name: "VideoGuides",
  components: {
    MessageCard,
  },
};
</script>

<style></style>
